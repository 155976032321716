<template>
  <v-dialog v-model="dialog" persistent max-width="400px">
    <v-card elevation="0">
      <v-card-title class="headline secondary t-bw-secondary--text">
        <v-spacer />
        <v-btn class="t-bw-secondary--text" icon @click="$emit('close:dialog')">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-6 d-flex justify-space-around align-center">
        <v-sheet color="white" class="mb-4 pa-4 d-flex justify-center">
          <div id="qrCode">
            <vue-qrcode :options="{ size: 240 }" :value="url"></vue-qrcode>
          </div>
        </v-sheet>
      </v-card-text>
      <v-card-actions class="pa-6 d-flex justify-space-around align-center">
        <v-btn
          small elevation="0" color="primary" class="t-bw-primary--text"
          @click="copyUrl"
        >
          {{ copied ? 'Copiado' : 'Copiar URL' }}
        </v-btn>
        <v-btn
          small elevation="0" color="primary" class="t-bw-primary--text"
          @click="downloadQr"
        >
          Descargar QR
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueQrcode from '@xkeshi/vue-qrcode'
import html2canvas from 'html2canvas'

export default {
  components: {
    VueQrcode
  },
  props: {
    dialog: Boolean,
    url: String
  },
  data() {
    return {
      copied: false
    }
  },
  methods: {
    copyUrl() {
      navigator.clipboard.writeText(this.url)
        .then(() => {
          this.copied = true
          setTimeout(() => {
            this.copied = false
          }, 1500) // cambiar el texto de vuelta después de 3 segundos
        })
        .catch(err => {
          console.error('Error al copiar la URL: ', err)
        })
    },
    downloadQr() {
      const element = document.querySelector("canvas")
      if (element) {
        const imgData = element.toDataURL('image/png')
        let a = document.createElement('a')
        a.href = imgData
        a.download = 'QRCode.png'
        a.click()
      } else {
        console.error('No se pudo encontrar el canvas del QR')
      }
    }
  }
}
</script>
