<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <p class='display-1 text--primary'>
          {{ $t('storage.titles.storage') }}
        </p>
      </v-list-item-content>
    </v-list-item>

    <v-data-table
      checkbox-color="primary"
      :headers='headers(selectedColumns)'
      :items='storages'
      :loading='loading'
      v-model='selectedItems'
      :show-select='showCheckBox'
      :options.sync='options'
      :server-items-length='totalFromServer'
      :no-results-text="$t('general.ui.not_search_found')"
      :no-data-text="$t('general.ui.not_data_found')"
      :loading-text="$t('general.notification.loadData')"
      :footer-props="{
        ...rowsPerPageItemsOptions,
        itemsPerPageText: $t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
    >
      <template v-slot:top>
        <v-row class='d-flex justify-space-between align-center'>
          <v-col lg='4' md='4' sm='12' xl='4' cols='6'>
            <v-text-field onkeypress='return (event.charCode != 34)' v-on:keyup.86='replaceSearch'
                          v-on:keyup.ctrl.86='replaceSearch'
                          dense
                          filled
                          rounded
                          color='secondary'
                          v-model='search'
                          append-icon='mdi-magnify'
                          :label="$t('general.titles.filter')"
                          :clearable='true'
                          single-line
                          hide-details
                          @keypress.enter="searchForText"
                          @click:clear="searchAll"
                          @click:append="searchForText"
            />
          </v-col>
          <v-col lg='4' md='4' sm='12' xl='4'>
            <v-btn
              small
              tile
              :elevation='0'
              color='#d8d8d896'
              style='border-radius: 1.5em;'
              @click='checkBoxShow'
              class='mr-1'
              :disabled='!(storages && storages.length > 0)'
            >
              <span v-if='!showCheckBox'>
                <v-icon small color='neuter' left
                >mdi-checkbox-multiple-marked-outline</v-icon
                >
                <span style='color: neuter'>{{
                    $t('general.buttons.checksBoxEnable')
                  }}</span>
              </span>
              <span v-else>
                <v-icon small color='neuter' left
                >mdi-checkbox-multiple-marked</v-icon
                >
                <span style='color: neuter'>{{
                    $t('general.buttons.checksBoxDisable')
                  }}</span>
              </span>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col lg='4' md='4' sm='8' xl='4'>
            <v-toolbar flat color='white'>
              <v-spacer></v-spacer>
              <v-badge
                bordered
                color="neuter"
                overlap
                :content="cantFilters"
                left
                :value="cantFilters"
              >
                <v-btn
                  small
                  tile
                  :elevation="0"
                  color="gray"
                  style="border-radius: 1.5em 0 0 1.5em"
                  @click="openFilters"
                  class="mr-1"
                >
                  <v-icon small color="neuter" left>mdi-filter-variant</v-icon>
                  <span class="neuter--text">{{
                      $t('general.buttons.filter')
                    }}</span>
                </v-btn>
              </v-badge>

              <more-options :items="selectedItems" :filters="computedFilters" string-template="storage" string-translate="storage"/>

              <v-btn
                small
                tile
                :elevation='0'
                class="ml-1"
                color='#d8d8d896'
                style='border-radius: 0 1.5em 1.5em 0;'
                v-if="
                  check([{ domain: 'StorageDevice', permisions: ['Write'] }])
                "
                @click='mgmtStorage'
              >
                <v-icon small color='neuter' left>mdi-plus</v-icon>
                <span style='color:neuter'>{{
                    $t('general.buttons.new')
                  }}</span>
              </v-btn>
            </v-toolbar>
          </v-col>
        </v-row>
        <!--FILTERS TOOLBAR *********************************************************************-->
        <v-toolbar
          class="mb-3"
          outlined
          elevation="0"
          style="border-radius: 0.3em"
          v-if="filtersChips && filtersChips.length > 0"
        >
          <v-row style="display: contents">
            <v-col cols="12">
              <v-chip-group show-arrows>
                <v-chip
                  v-for="chip in filtersChips"
                  :key="chip.value"
                  close
                  small
                  @click:close="cleanFilter(chip)"
                >
                  <v-icon left color="t-adj-primary darken-3" small>mdi-filter-variant</v-icon>
                  {{ chip.name }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-toolbar>
        <!--FILTERS TOOLBAR *********************************************************************-->
      </template>
      <template v-slot:header.action="{ header }">
        <div class="d-flex justify-center align-center align-content-center text-center">
          <span>{{ header.text }}</span>
          <ExtraColumnsComponent />
        </div>
      </template>

      <template v-slot:item.statusLine="{ item }">
        <td class="pa-0">
          <span class="dot-validated" v-if="item.status === 'VALIDATED'"/>
          <span class="dot-validating" v-if="item.status === 'VALIDATING'"/>
          <span class="dot-pending" v-if="item.status === 'PENDING'"/>
          <span class="dot-errored" v-if="item.status === 'ERRORED'" />
        </td>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip small v-if="item.status === 'VALIDATED'">{{ $t('records.filters.status.validated') }}</v-chip>
        <v-chip small v-if="item.status === 'PENDING'">{{ $t('records.filters.status.pending') }}</v-chip>
        <v-chip small v-if="item.status === 'VALIDATING'">{{ $t('records.filters.status.validating') }}</v-chip>
        <v-chip small v-if="item.status === 'ERRORED'">{{ $t('records.filters.status.errored') }}</v-chip>
      </template>
      <template v-slot:item.productive='{ item }'>
        <v-icon small class='mr-4' color='success' v-if='item.productive'>
          mdi-checkbox-marked-circle-outline
        </v-icon>
        <v-icon small class='mr-4' color='metal' v-else>
          mdi-checkbox-marked-circle-outline
        </v-icon>
      </template>
      <template v-slot:item.active='{ item }'>
        <v-icon small class='mr-4' color='success' v-if='item.isActive'>
          mdi-checkbox-marked-circle-outline
        </v-icon>
        <v-icon small class='mr-4' color='metal' v-if='!item.isActive'>
          mdi-checkbox-marked-circle-outline
        </v-icon>
      </template>
      <template v-slot:item.control_label='{ item }'>
        <v-chip class='ma-1' small>{{ item.control_label }}</v-chip>
      </template>
      <template v-slot:item.primaryLocation.name='{ item }'>
        <v-chip class='ma-1' small>{{ item.primaryLocation.name }}</v-chip>
      </template>

      <template v-slot:item.area='{ item }'>
        {{ item.area || '-' }}
      </template>

      <template v-slot:item.latitude='{ item }'>
        {{ item.latitude || '-' }}
      </template>

      <template v-slot:item.longitude='{ item }'>
        {{ item.longitude || '-' }}
      </template>

      <template v-slot:item.telephone='{ item }'>
        {{ item.telephone || '-' }}
      </template>

      <template v-slot:item.email='{ item }'>
        {{ item.email || '-' }}
      </template>

      <template v-slot:item.description='{ item }'>
        <ReadMore
          v-if="item.description && translate(item.description) !== ''"
          :classes="'mb-0'"
          :text="translate(item.description)"
          :max-chars="10"
          :font-size="14"
          layout="dialog"
        />
        <span v-else>-</span>
      </template>

      <template v-slot:item.classificationType='{ item }'>
        {{ findClassification(item) }}
      </template>

      <template v-slot:item.comercialSite='{ item }'>
        {{ item.comercialSite || '-' }}
      </template>

      <template v-slot:item.storageDeviceType='{ item }'>
        {{ translate(item.storageDeviceType) }}
      </template>

      <template v-slot:item.measurementUnit='{ item }'>
        {{ item.measurementUnit ? translate(item.measurementUnit) : '-' }}
      </template>

      <template v-slot:item.mediumHeight='{ item }'>
        {{ item.mediumHeight || '-' }}
      </template>

      <template v-slot:item.mediumTemperature='{ item }'>
        {{ item.mediumTemperature || '-' }}
      </template>

      <template v-slot:item.color='{ item }'>
        <span v-if="item.color" :style="`border: 1px solid grey; display: block; width: 15px; height: 15px; background-color: ${item.color}`"></span>
        <span v-else>-</span>
      </template>

      <template v-slot:item.address1='{ item }'>
        <ReadMore
          v-if="item.address1"
          :classes="'mb-0'"
          :text="item.address1"
          :max-chars="10"
          :font-size="14"
        />
        <span v-else>-</span>
      </template>

      <template v-slot:item.address2='{ item }'>
        {{ item.address2 || '-' }}
      </template>

      <template v-slot:item.country='{ item }'>
        {{ item.country || '-' }}
      </template>

      <template v-slot:item.city='{ item }'>
        {{ item.city || '-' }}
      </template>

      <template v-slot:item.state='{ item }'>
        {{ item.state || '-' }}
      </template>

      <template v-slot:item.zipCode='{ item }'>
        {{ item.zipCode || '-' }}
      </template>

      <template v-slot:item.action='{ item }'>
        <v-tooltip bottom>
          <template v-slot:activator='{ on }'>
            <v-icon
              v-on='on'
              color='neuter'
              small
              class='mr-2'
              @click='showItem(item)'
              v-if="check([{ domain: 'StorageDevice', permisions: ['Read'] }])"
            >
              mdi-eye-outline
            </v-icon>
          </template>
          <span>
            {{ $t('general.buttons.show') }}
            <span style='font-size: 12px; color: #AAAAAA'>
              {{ $t('menu.locations') }}
            </span>
          </span>
        </v-tooltip>
        <v-dialog
          v-if="check([{ domain: 'Location', permisions: ['Validate'] }]) && item.status === 'PENDING'"
          v-model="manageAuthorizationDialog[item.id]"
          persistent width="600px"
        >
          <template v-slot:activator="{ on: dialog }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-icon
                  v-on="{ ...dialog, ...tooltip }"
                  color="neuter"
                  small
                  class="mr-2"
                >
                  mdi-map-marker-check-outline
                </v-icon>
              </template>
              <span>
                {{ $t('general.buttons.manageAuthorization') }}
                <span style="font-size: 12px; color: #aaaaaa">
                  {{ $t("menu.locations") }}
                </span>
              </span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title class="headline">{{ $t('general.buttons.manageAuthorization') }}</v-card-title>
            <v-card-text>
              <v-select
                :placeholder="$t('tokens.fields.walletAddress')"
                :items="statusItems"
                item-text="name"
                item-value="value"
                v-model="storageStatus"
                clearable
                small
                flat
                solo
                color="primary"
                item-color="primary"
                background-color="#EAEAEA80"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small text color="gray" @click="dialogAuthClose(item)">
                {{ $t("general.buttons.cancel") }}
              </v-btn>
              <v-btn small text color="primary" @click="manageAuthorization(item)" :disabled="!storageStatus">
                {{ $t("general.buttons.confirm") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-tooltip bottom>
          <template v-slot:activator='{ on }'>
            <v-icon
              v-on='on'
              v-if="
                check([{ domain: 'StorageDevice', permisions: ['Update'] }])
              "
              color='neuter'
              class='mr-2'
              small
              @click='editItem(item)'
            >
              mdi-square-edit-outline
            </v-icon>
          </template>
          <span>
            {{ $t('general.buttons.edit') }}
            <span style='font-size: 12px; color: #AAAAAA'>
              {{ $t('storage.titles.storage') }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator='{ on }'>
            <v-icon
              v-on='on'
              v-if="
                check([{ domain: 'StorageDevice', permisions: ['Delete'] }])
              "
              color='neuter'
              class='mr-2'
              small
              @click='deleteItemDialog(item)'
            >
              mdi-trash-can-outline
            </v-icon>
          </template>
          <span>
            {{ $t('general.buttons.delete') }}
            <span style='font-size: 12px; color: #AAAAAA'>
              {{ $t('storage.titles.storage') }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom v-if="isHypertIntegration">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="neuter"
              style="height: 14px !important;"
              @click="downloadHTReport(item)"
              v-show="!loadingHTReport[item.id]"
            >
              $hypert
            </v-icon>
            <v-progress-circular v-show="loadingHTReport[item.id]" color="neuter" indeterminate size='12' width='2' />
          </template>
          <span>
            {{ $t('general.buttons.export') }}
            <span style='font-size: 12px; color: #AAAAAA'>
              HyperT
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-menu
              bottom
              left
              v-model='moreOptions[item.id]'
            >
              <template v-slot:activator='{ on:menu }'>
                <v-icon
                  v-on='menu'
                  class='mx-1'
                  color="neuter"
                  size='20'
                  @click='$set(moreOptions, item.id, !moreOptions[item.id])'
                >
                  mdi-dots-horizontal
                </v-icon>
              </template>
              <v-list elevation='0'>
                <v-list-item v-if="check([{ domain: 'StorageDevice', permisions: ['Validate'] }]) && item.status === 'PENDING'">
                  <template #default>
                    <ConfirmDialog
                      :show="!loadingAuth[item.id]"
                      :item="item"
                      :is-btn="true"
                      :title="item.status === 'VALIDATED' ? $t('general.buttons.unauthorize') : $t('general.buttons.authorize')"
                      :dialog-text="$t('locations.notification.changeStateConfirm')"
                      :ts-text="$t('menu.locations')"
                      :btn-title="item.status === 'VALIDATED' ? $t('general.buttons.unauthorize') : $t('general.buttons.authorize')"
                      :btn-icon="item.status === 'VALIDATED' ? 'mdi-map-marker-off-outline' : 'mdi-map-marker-outline'"
                      :w-icon="true"
                      :i-left="true"
                      :small="true"
                      color="neuter"
                      styles="mr-2"
                      action="validateItem"
                    >
                      <template v-slot:loading>
                        <v-progress-circular
                          v-show="loadingAuth[item.id]" size="12" class="mr-2" width="2" indeterminate />
                      </template>
                    </ConfirmDialog>
                  </template>
                </v-list-item>
                <v-list-item>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" small text @click.stop='showVersions(item)' :disabled="item.versionNumber === 1">
                        <v-icon
                          color='black'
                          left
                          small
                        >
                          mdi-file-document-outline
                        </v-icon>
                        {{ $t('general.buttons.showVersions') }}
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('general.buttons.showVersions') }}
                      <span style="font-size: 12px; color: #aaaaaa">
                        {{ $t("menu.locations") }}
                      </span>
                    </span>
                  </v-tooltip>
                </v-list-item>
                <v-list-item v-if="ifPresentIntegration('IG') && check([{ domain: 'QrUser', permisions: ['Read'] }])">
                  <v-btn small text @click.stop='previewQR(item)'>
                    <v-icon
                      color='black'
                      left
                      small
                    >
                      mdi-open-in-new
                    </v-icon>
                    {{ $t('locations.titles.previewQr') }}
                  </v-btn>
                </v-list-item>
                <v-list-item v-if="ifPresentIntegration('IG') && check([{ domain: 'QrUser', permisions: ['Read'] }])">
                  <!--   copy or download qr url     -->
                  <v-btn small
                         text
                         @click="openQrCodeDialog(item)"
                  >
                    <v-icon
                      color='black'
                      left
                      small
                    >
                      mdi-qrcode
                    </v-icon>
                    {{ $t("general.buttons.qrcode") }}
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <span>
            {{ $t('general.buttons.options') }}
            <span style="font-size: 12px; color: #aaaaaa">
              {{ $t('menu.records') }}
            </span>
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-row justify='center'>
      <v-dialog v-model='showDialog' persistent width='600px'>
        <v-card>
          <v-card-title class="headline secondary t-bw-secondary--text">{{ title }}</v-card-title>
          <v-card-text class="pa-6">{{ dialogText }}</v-card-text>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn small text color='neuter' @click='dialogClose'>
              {{ $t('general.buttons.cancel') }}
            </v-btn>
            <v-btn small elevation="0" color="error" class="t-bw-error--text" @click='deleteItem'>
              {{ $t('general.buttons.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--  SHOW VERSIONS  -->
      <VersionsComponent :dialog="showVersionsDialog" @close:dialog="showVersionsDialog = $event" />

      <!--  SHOW QR CODE DIALOG  -->
      <QrCodeDialog @close:dialog="closeQrCodeDialog()" :dialog="qrCodeDialog" :url="qrCodeUrl" />
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/plugins/i18n'
import _ from 'lodash'
import {mapboxMixin} from "@/mixins/mapbox.mixin";
import {mapActions, mapGetters} from 'vuex'
import MapBox from '@/components/vueMapbox/MapBox.vue'
import StorageService from '../../../services/StorageService'
import PermisionService from '../../../services/PermisionService'
import LanguajeService from '@/services/LanguajeService'
import VersionsComponent from "@/module/configuration/storage/components/VersionsComponent.vue";
import ConfirmDialog from "../../../components/ConfirmDialog";
import MoreOptions from "@/components/MoreOptions.vue";
import ExtraColumnsComponent from "@/module/configuration/storage/components/ExtraColumnsComponent.vue";
import ReadMore from "@/components/ReadMoreComponent.vue";
import QrCodeDialog from "@/module/reports/traceability/QrCodeDialog.vue";


const FILTER_VALUES = [
    'template_names',
    'company_id',
    'process_ids',
    'products_ids',
    'seasons_ids',
    'person_in_charge_ids',
    'start_record_date_time',
    'end_record_date_time',
    'structure',
    'pageableDTO',
    'record_ids',
    'min_punctuation',
    'max_punctuation',
    'surveyStateType',
    'sdi_origin_ids',
    'sellers_date'
  ]

  const KEYS = [
    'pageableDTO',
    'storage_device_types_ids',
    'minArea',
    'maxArea',
    'isActive',
    'productive',
    'status',
    'area',
    'stringFilter',
    'primaryLocationIds',
    'ids',
    'classificationTypes'
  ]

  const PROFILE = JSON.parse(localStorage.getItem('profile'));

  export default {
    components: {
      QrCodeDialog,
      ReadMore,
      MoreOptions,
      ExtraColumnsComponent,
      VersionsComponent,
      MapBox,
      ConfirmDialog
    },
    data() {
      return {
        // dialogs
        dialog: false,
        ready: false,
        showDialog: false,
        showVersionsDialog: false,
        loadingVersions: false,
        loadingHTReport: {},
        loadingAuth: {},
        moreOptions: {},
        title: '',
        dialogText: '',
        profile: null,
        valid: false,
        menu: false,
        max: 80,
        controls: {
          polygon: true,
          point: true,
          trash: true,
        },
        // draw controls
        editedIndex: -1,
        search: '',
        units: null,
        // units
        areaWithUnit: '',
        areaSuffix: '',
        editedItem: this.getModal(),
        deletingItem: {},
        loadingExport: false,
        selectedItems: [],
        showCheckBox: false,
        showItemDetailsDialog: false,
        turfDefaultUnit: null,
        selectedItem: {},
        locationsData: [],
        countryList: ['Brazil'],
        isHypertIntegration: false,
        options: {},
        manageAuthorizationDialog: {},
        storageStatus: null,
        statusItems: [
          { name: i18n.t('records.filters.status.validated'), value: "VALIDATED" },
          { name: i18n.t('records.filters.status.errored'), value: "ERRORED" }
        ],
        defaultFilters: {
          stringFilter: "",
          pageableDTO: {
            page: 0,
            size: 10,
            sortBy: 'control_label',
            direction: 'DESC',
          },
          isActive: null
        },
        rowsPerPageItemsOptions: {
          itemsPerPageOptions: [10, 20, 50, 100, 200],
        },
        qrCodeDialog: false,
        qrCodeUrl: null,
      }
    },

    mixins: [mapboxMixin],

    watch: {
      options: {
        async handler() {
          await this.searchText()
        },
        deep: true,
      },
    },

    mounted() {
      this.type = 'storages'
      this.$store.commit('ui/FILTERS_ORIGINAL_STATE')

      let filtersStorages = JSON.parse(localStorage.getItem('filtersStorages'))

      if (filtersStorages) {
        this.filtersObjects = filtersStorages.filtersObjects
          ? filtersStorages.filtersObjects
          : this.filtersObjects

        this.$root.$emit('set:locations:filters', {
          locations: filtersStorages.filtersObjects.storages.primaryLocationIds,
          storageDevice: filtersStorages.filtersObjects.storages.ids
        });

        this.filters.stringFilter = filtersStorages.filtersObjects.stringFilter && this.filtersObjects.stringFilter !== ''
          ? this.filtersObjects.stringFilter
          : null

        this.filters.isActive = typeof filtersStorages.filtersObjects.locations.isActive === 'boolean'
          ? filtersStorages.filtersObjects.locations.isActive
          : null

        this.filters.status =
          filtersStorages.filtersObjects.locations.status &&
          filtersStorages.filtersObjects.locations.status.length > 0
            ? this.filtersObjects.locations.status
            : []

        this.filters.storage_device_types_ids =
          filtersStorages.filtersObjects.storage_device_types_ids &&
          filtersStorages.filtersObjects.storage_device_types_ids.length > 0
            ? this.getByProperty(
              filtersStorages.filtersObjects.storage_device_types_ids,
              'storage_device_type_id'
            )
            : []

        this.filters.ids =
          filtersStorages.filtersObjects.storages.ids &&
          filtersStorages.filtersObjects.storages.ids.length > 0
            ? filtersStorages.filtersObjects.storages.ids
            : []

        this.filters.primaryLocationIds =
          filtersStorages.filtersObjects.storages.primaryLocationIds &&
          filtersStorages.filtersObjects.storages.primaryLocationIds.length > 0
            ? filtersStorages.filtersObjects.storages.primaryLocationIds
            : []

        this.filters.minArea = filtersStorages.filtersObjects.storages.area && filtersStorages.filtersObjects.storages.area[0] > 0
          ? filtersStorages.filtersObjects.storages.area[0]
          : null
        this.$set(this.filtersObjects.storages.area, 0, this.areaRanges.secondary[0] ? this.areaRanges.secondary[0] : 0)

        this.filters.maxArea = filtersStorages.filtersObjects.storages.area && filtersStorages.filtersObjects.storages.area[1] >= 1
          ? filtersStorages.filtersObjects.storages.area[1]
          : null
        this.$set(this.filtersObjects.storages.area, 1, this.areaRanges.secondary[1] ? this.areaRanges.secondary[1] : 0)

        this.filtersChips =
          filtersStorages.filtersChips && filtersStorages.filtersChips.length > 0
            ? filtersStorages.filtersChips
            : []
        this.cantFilters =
          filtersStorages.cantFilters && filtersStorages.cantFilters.length > 0
            ? filtersStorages.cantFilters
            : null
      }

      this.$root.$on('setFiltersStorages', () => {
        this.closeFilterDialog()
      })

      this.$root.$on('validateItem', (item) => {
        this.validateItem(item)
      })
    },

    computed: {
      ...mapGetters({
        storages: 'storage/storagesTable',
        totalFromServer: 'storage/totalFromServerTable',
        areaRanges: 'ui/GET_LOCATION_RANGES',
        requireRules: 'general/requireRules',
        locationsList: 'authority/authoritiesLocations',
        storageDeviceTypesActiveByCompany: 'storage_type/storageDeviceTypesActiveByCompany',
        allUnits: 'units/allObjectUnits',
        requireAndMaxRules: 'general/requireAndMaxRules',
        onlyPositiveNumberFormat: 'general/onlyPositiveNumberFormat',
        equalObjectsCompareRule: 'general/equalObjectsCompareRule',
        classifications: "storage/GET_CLASSIFICATIONS",
        // -------- ui --------------------
        headers: "storage/GET_HEADERS",
        selectedColumns: "storage/GET_SELECTED_COLUMNS",
        ifPresentIntegration: "integration/IS_PRESENT",
      }),
      computedFilters() {
        return _.pick(this.filters, KEYS)
      },
      loading: {
        get() {
          return this.$store.getters['authority/loading']
        },
        set(val) {
          this.$store.commit('authority/SET_LOADING_OFF', val)
        },
      },
      type: {
        get() {
          return this.$store.getters['ui/GET_FILTER_TYPE']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTER_TYPE', val)
        },
      },
      drawer: {
        get() {
          return this.$store.getters['ui/GET_DRAWER']
        },
        set(val) {
          this.$store.dispatch('ui/SET_DRAWER', val)
        },
      },
      cantFilters: {
        get() {
          return this.$store.getters['ui/GET_COUNT_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_COUNT_FILTERS', val)
        },
      },
      filtersObjects: {
        get() {
          return this.$store.getters['ui/GET_OBJECTS_TO_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_OBJECTS_TO_FILTERS', val)
        },
      },
      filters: {
        get() {
          return this.$store.getters['ui/GET_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTERS', val)
        },
      },
      filtersChips: {
        get() {
          return this.$store.getters['ui/GET_FILTERS_CHIPS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTERS_CHIPS', val)
        },
      },
      mapConfig: {
        get() {
          return this.$store.getters['storage/mapConfig']
        },
        set(val) {
          this.$store.commit('storage/SET_MAP_CONFIG', val)
        },
      },
    },

    created() {
      this.$store.commit('ui/SET_FILTERS_SORT', []);
      this.initialize()
    },

    methods: {
      ...mapActions({
        fetchListStorages: 'storage/fetchFilteredStoragesForTable',
        fetchStorageById: 'storage/fetchStorageById',
        fetchListVersions: 'locations/fetchListVersions',
        changeActiveValue: 'storage/changeActiveValue',
        downloadHypertReport: 'storage/downloadHypertReport',
        exportCSV: 'storage/exportCSV',
        fetchAllGroupUnits: 'units/fetchAllGroupUnits',
        fetchStorageTypesActiveByCompany: 'storage_type/fetchStorageTypesActiveByCompany',
        saveStorage: 'storage/saveStorage',
        authorizeStorageOrNot: 'storage/authorizeStorageOrNot',
        fetchAllUnits: 'units/fetchAllUnits',
        fetchExtraColumns: 'storage/fetchExtraColumns',
        fetchListStorage: "authority/getAuthoritiesStorageDevices"
      }),
      findClassification(item) {
        const classification = _.find(this.classifications, { id: item.classificationType });

        if (classification) {
          return this.translate(classification.languageKey);
        } else {
          return '-';
        }
      },
      mapDataReload(){
        this.fetchListStorage([{
          userId: PROFILE.id,
          companyId: PROFILE.company_id,
          isActive: true
        }, this.$toast])
      },
      openQrCodeDialog(item) {
        this.qrCodeUrl = `${this.profile.qrDomain}/producer?sl=${item.id}&type=qrp&pl=${item.primaryLocationId}`
        this.qrCodeDialog = true
      },
      closeQrCodeDialog() {
        this.qrCodeDialog = false
        this.qrCodeUrl = null
      },
      previewQR(item) {
        window.open(
          encodeURI(`${this.profile.qrDomain}/producer?sl=${item.id}&type=qrp&pl=${item.primaryLocationId}`),
          '_blank'
        );
      },
      async cleanFilter(chip) {
        let tag = chip.value
        let filtersStorages = JSON.parse(localStorage.getItem('filtersStorages'))
        if (this.filters[tag]) {
          if (this.filtersObjects[tag]) {
            this.$set(this.filtersObjects, tag, [])
            filtersStorages.filtersObjects[tag] = []
          }
          this.$set(this.filters, tag, [])
        }

        if (tag === 'isActive') {
          this.filters[tag] = null
          this.filtersObjects.locations[tag] = null
          filtersStorages.filtersObjects.locations[tag] = null
        }

        if (tag === 'stringFilter') {
          this.filters[tag] = null
          this.filtersObjects[tag] = null
        }

        if (tag === 'productive') {
          this.filters[tag] = null
          this.filtersObjects.locations[tag] = null
          filtersStorages.filtersObjects.locations[tag] = null
        }

        if (tag === 'status') {
          this.filters[tag] = []
          this.filtersObjects.locations[tag] = []
          filtersStorages.filtersObjects.locations[tag] = []
        }

        if (tag === 'minArea') {
          this.filters[tag] = null
          this.$set(this.filtersObjects.storages.area, 0, this.areaRanges.secondary[0] ? this.areaRanges.secondary[0] : 0)
          filtersStorages.filtersObjects.storages.area[0] = this.areaRanges.secondary[0] ? this.areaRanges.secondary[0] : 0
        }

        if (tag === 'maxArea') {
          this.filters[tag] = null
          this.$set(this.filtersObjects.storages.area, 1, this.areaRanges.secondary[1] ? this.areaRanges.secondary[1] : 0)
          filtersStorages.filtersObjects.storages.area[1] = this.areaRanges.secondary[1] ? this.areaRanges.secondary[1] : 0
        }

        if (tag === 'ids') {
          this.$set(this.filters, tag, [])
          this.$set(this.filtersObjects.storages, tag, [])

          this.$root.$emit("clear:locations:filters", {
            locations: this.filters['primaryLocationIds'],
            storageDevice: []
          })

          filtersStorages.filtersObjects.storages[tag] = []
        }

        if (tag === 'classificationTypes') {
          this.$set(this.filters, tag, [])
          this.$set(this.filtersObjects.storages, tag, [])

          filtersStorages.filtersObjects.storages[tag] = []
        }

        if (tag === 'primaryLocationIds') {
          this.$set(this.filters, tag, [])
          this.$set(this.filtersObjects.storages, tag, [])

          this.$root.$emit("clear:locations:filters", {
            locations: [],
            storageDevice: this.filters['ids']
          })

          filtersStorages.filtersObjects.storages[tag] = []
        }

        localStorage.setItem('filtersStorages', JSON.stringify(filtersStorages))
        await this.applyFilters()
        this.filteredChips()
        this.getCantFilters()
      },
      translate(key, uppercase) {
        return LanguajeService.getKey3(key)
      },
      async applyFilters() {
        this.loading = true;
        let filtersStorages = JSON.parse(localStorage.getItem('filtersStorages'))

        if (filtersStorages) {
          this.filters.stringFilter = filtersStorages.filtersObjects.stringFilter && this.filtersObjects.stringFilter !== ''
            ? this.filtersObjects.stringFilter
            : null

          this.filters.isActive = typeof filtersStorages.filtersObjects.locations.isActive === 'boolean'
            ? filtersStorages.filtersObjects.locations.isActive
            : null

          this.filters.productive = typeof filtersStorages.filtersObjects.locations.productive === 'boolean'
            ? filtersStorages.filtersObjects.locations.productive
            : null

          this.filters.status =
            filtersStorages.filtersObjects.locations.status &&
            filtersStorages.filtersObjects.locations.status.length > 0
              ? filtersStorages.filtersObjects.locations.status
              : []

          this.filters.classificationTypes =
            filtersStorages.filtersObjects.storages.classificationTypes &&
            filtersStorages.filtersObjects.storages.classificationTypes.length > 0
              ? this.getByProperty(
                filtersStorages.filtersObjects.storages.classificationTypes,
                'id'
              )
              : []

          this.filters.storage_device_types_ids =
            filtersStorages.filtersObjects.storage_device_types_ids &&
            filtersStorages.filtersObjects.storage_device_types_ids.length > 0
              ? this.getByProperty(
                filtersStorages.filtersObjects.storage_device_types_ids,
                'storage_device_type_id'
              )
              : []

          this.filters.ids =
            filtersStorages.filtersObjects.storages.ids &&
            filtersStorages.filtersObjects.storages.ids.length > 0
              ? filtersStorages.filtersObjects.storages.ids
              : []

          this.filters.primaryLocationIds =
            filtersStorages.filtersObjects.storages.primaryLocationIds &&
            filtersStorages.filtersObjects.storages.primaryLocationIds.length > 0
              ? filtersStorages.filtersObjects.storages.primaryLocationIds
              : []

          this.filters.minArea = filtersStorages.filtersObjects.storages.area && filtersStorages.filtersObjects.storages.area[0] > 0
            ? filtersStorages.filtersObjects.storages.area[0]
            : null

          this.filters.maxArea = filtersStorages.filtersObjects.storages.area && filtersStorages.filtersObjects.storages.area[1] >= 1
            ? filtersStorages.filtersObjects.storages.area[1]
            : null
        }

        const direction =
          this.options.sortDesc.length === 0 || this.options.sortDesc[0]
            ? 'desc'
            : 'asc'

        const sort =
          this.options.sortBy.length === 0
            ? "control_label"
            : _.filter(this.headers(this.selectedColumns), {value: this.options.sortBy[0]})[0].val;

        this.$set(this.filters, 'pageableDTO', {
          page: this.options.page > 0 ? this.options.page - 1 : 0,
          size: this.options.itemsPerPage > -1 ? this.options.itemsPerPage : this.totalFromServer,
          sortBy: sort,
          direction: direction,
        })

        await this.fetchListStorages([_.pick(this.filters, KEYS), this.$toast]).finally(() => {
          this.loading = false
        })
      },
      async closeFilterDialog() {
        this.search = "";
        await this.applyFilters()
        this.filteredChips()
        this.getCantFilters()
      },
      async openFilters() {
        this.type = 'storages'

        let filtersStorages = JSON.parse(localStorage.getItem('filtersStorages'))

        await this.$root.$emit("set:locations:filters", {
          locations: filtersStorages.filtersObjects.storages.primaryLocationIds,
          storageDevice: filtersStorages.filtersObjects.storages.ids
        })

        this.drawer = true
      },
      filteredChips() {
        let chips = []
        const filters = _.pick(this.filters, KEYS)

        console.log(filters)

        _.forEach(filters, (value, key) => {
          if (
            ![FILTER_VALUES, 'stringFilter', 'ids', 'primaryLocationIds', 'status', 'classificationTypes'].includes(key) &&
            this.filters[key] !== null &&
            this.filters[key] !== undefined &&
            this.filters[key].length > 0
          ) {
            chips.push({
              value: key,
              name: _.filter(this.headers(this.selectedColumns), { val: key })[0].text,
            })
          } else {
            if (key === 'ids' && (value && value.length > 0)) {
              chips.push({
                value: key,
                name: i18n.t("reports.fields.storage_device"),
              })
            }

            if (key === 'status' && (value && value.length > 0)) {
              chips.push({
                value: key,
                name: i18n.t("records.fields.status"),
              })
            }

            if (key === 'classificationTypes' && (value && value.length > 0)) {
              chips.push({
                value: key,
                name: i18n.t("locations.fields.classification"),
              })
            }

            if (key === 'primaryLocationIds' && (value && value.length > 0)) {
              chips.push({
                value: key,
                name: i18n.t("reports.fields.location"),
              })
            }

            if (key === 'stringFilter' && value && value !== '') {
              chips.push({
                value: key,
                name: i18n.t("general.fields.text"),
              })
            }

            if (key === 'minArea' && value > this.areaRanges.secondary[0]) {
              chips.push({
                value: key,
                name: 'Min ' + i18n.t("locations.fields.area"),
              })
            }

            if (key === 'maxArea' && (value >= 1 && value < this.areaRanges.secondary[1])) {
              chips.push({
                value: key,
                name: 'Max ' + i18n.t("locations.fields.area"),
              })
            }

            if (typeof this.filters.isActive === 'boolean' && key === 'isActive') {
              chips.push({
                value: key,
                name: _.filter(this.headers(this.selectedColumns), { val: key })[0].text,
              })
            }

            if (typeof this.filters.productive === 'boolean' && key === 'productive') {
              chips.push({
                value: key,
                name: _.filter(this.headers(this.selectedColumns), { val: key })[0].text,
              })
            }
          }
        })

        this.filtersChips = chips
      },
      getCantFilters() {
        const filters = _.pick(this.filters, KEYS)
        let cant = 0
        _.forEach(filters, (value, key) => {
          if (![...FILTER_VALUES, 'status', 'classificationTypes', 'isActive', 'productive', 'area', 'minArea', 'maxArea', 'stringFilter', 'ids', 'primaryLocationIds'].includes(key) && value !== null) {
            cant += value.length
          }

          cant +=
            ((key === 'minArea' && value > this.areaRanges.secondary[0]) || (key === 'maxArea' && (value >= 1 && value < this.areaRanges.secondary[1])))
              ? 1
              : 0

          cant +=
            (key === 'status' && (value && value.length > 0))
              ? 1
              : 0

          cant +=
            (key === 'classificationTypes' && (value && value.length > 0))
              ? 1
              : 0

          cant +=
            (key === 'isActive' && typeof value === 'boolean')
              ? 1
              : 0

          cant +=
            (key === 'productive' && typeof value === 'boolean')
              ? 1
              : 0

          cant +=
            (key === 'stringFilter' && value && value !== '')
              ? 1
              : 0

          cant +=
            (key === 'ids' && (value && value.length > 0))
              ? 1
              : 0

          cant +=
            (key === 'primaryLocationIds' && (value && value.length > 0))
              ? 1
              : 0
        })
        this.cantFilters = cant
      },
      async manageAuthorization(item) {
        this.$set(this.loadingAuth, item.id, true);

        await this.authorizeStorageOrNot([{
          id: item.id,
          status: this.storageStatus
        }, this.$toast])
          .finally(() => {
            this.$set(this.loadingAuth, item.id, false);
            this.manageAuthorizationDialog[item.id]  = false
          })
      },
      async validateItem(item) {
        this.$set(this.loadingAuth, item.id, true);

        setTimeout(async () => {
          await this.authorizeStorageOrNot([{
            id: item.id,
            status: item.status === 'VALIDATED' ? 'ERRORED' : 'VALIDATED'
          }, this.$toast])
          .finally(() => {
            this.$set(this.loadingAuth, item.id, false);
          })
        }, 1500)
      },
      async searchAll() {
        this.loading = true

        await this.fetchListStorages([this.filters, this.$toast]).finally(() => {
          this.loading = false
        })
      },
      async searchForText() {
        this.options.page = 1
        await this.searchText()
      },
      async searchText() {
        this.loading = true

        let filters = {
          ...this.filters,
          stringFilter: this.search,
          pageableDTO: {
            page: 0,
            size: 10,
            sortBy: 'control_label',
            direction: 'DESC'
          },
          isActive: null
        }

        const direction =
          this.options.sortDesc.length === 0 || this.options.sortDesc[0]
            ? 'asc'
            : 'desc'
        const sort =
          this.options.sortBy.length === 0
            ? 'control_label'
            : _.filter(this.headers(this.selectedColumns), { value: this.options.sortBy[0] })[0].value
        filters.pageableDTO = {
          page: this.options.page - 1,
          size: this.options.itemsPerPage,
          sortBy: sort,
          direction: direction,
        }

        if (this.search && this.search !== '') {
          await this.fetchListStorages([filters, this.$toast])
        } else {
          await this.closeFilterDialog()
        }

        this.loading = false
      },
      clearSearch() {
        this.storages = _.cloneDeep(this.storages);
      },
      async initialize() {
        this.loading = true
        this.profile = JSON.parse(localStorage.getItem('profile'))
        if (this.profile)
          this.isHypertIntegration = this.$store.getters['integration/IS_PRESENT']('HYPERT');

        await this.fetchExtraColumns();

        // await this.fetchListStorages([this.filters, this.$toast])
        //   .then(() => {
        //     this.storagesList = _.cloneDeep(this.storages)
        //   })

        await this.fetchAllGroupUnits(this.$toast).finally(() => {
          this.loading = false
        })
      },
      checkBoxShow() {
        this.showCheckBox = !this.showCheckBox

        if (!this.showCheckBox) this.selectedItems = []
      },
      getByProperty(array, property) {
        const allAreObjectsWithProperty = array.every(item =>
          typeof item === 'object' && item !== null && Object.hasOwnProperty.call(item, property)
        );

        if (allAreObjectsWithProperty) {
          return _.map(array, property);
        } else {
          return array;
        }
      },
      async exportCSVStorages() {
        this.loadingExport = true
        const data = {
          language: LanguajeService.getLenguajeName(),
          versionIds: this.getByProperty(this.selectedItems, 'id'),
        }
        await this.exportCSV([data, this.$toast]).finally(() => {
          this.loadingExport = false
        })
      },
      async downloadHTReport(item) {
        this.$set(this.loadingHTReport, item.id, true)
        const data = {
          language: LanguajeService.getLenguajeName(),
          storageDeviceId: item.id,
        }

        await this.downloadHypertReport([data, this.$toast]).finally(() => {
          this.$set(this.loadingHTReport, item.id, false)
        })
      },
      getItem(item) {
        this.editedIndex = this.storages.indexOf(item)
        this.editedItem = Object.assign({}, item)
      },
      check(permisions) {
        return PermisionService.check(permisions)
      },

      getModal() {
        return {
          id: null,
          latitude: '',
          longitude: '',
          control_label: '',
          area: null,
          gln: '',
          measurementUnit: null,
          storageDeviceType: null,
          location: null,
          address1: "",
          address2: "",
          city: "",
          country: "",
          state: "",
          zipCode: "",
          productive: true,
          active: false,
        }
      },
      async editItem(item) {
        localStorage.setItem('storage_editOrSave', item.id)
        localStorage.setItem('storage:isVersion', JSON.stringify(0));

        await this.$router.push({ name: 'storage-management', params: { uuid: item.id, action: 'edit' } })
      },
      deleteItem() {
        this.dialogClose()
        StorageService.delete(this.deletingItem.id)
          .then((resp) => {
            this.$toast.success(this.$t('storage.notification.deleted'), {
              icon: 'mdi-check-circle',
              queueable: true
            })
          })
          .catch((err) => {
            if (err.message.includes('406')) {
              this.$toast.info(this.$t('storage.notification.inUse'), {
                icon: 'mdi-information-slab-circle',
                queueable: true
              })
            } else {
              this.$toast.error(this.$t('storage.notification.deletedError'), {
                queueable: true,
              })
            }
          })
          .finally(() => {
            this.fetchListStorages([{...this.filters, stringFilter: this.search || null}, this.$toast])
            this.mapDataReload()
          })
      },
      deleteItemDialog(item) {
        this.deletingItem = item
        this.dialogShow({
          title: i18n.t('general.titles.alert'),
          dialogText: i18n.t('general.notification.deleteItems'),
        })
      },
      dialogShow(params) {
        this.title = params.title
        this.dialogText = params.dialogText
        this.showDialog = true
      },
      dialogClose() {
        this.showDialog = false
      },
      dialogAuthClose(item) {
        this.manageAuthorizationDialog[item.id] = false;
        this.storageStatus= null
      },
      mgmtStorage() {
        localStorage.setItem('storage_editOrSave', null)
        this.$router.push({ name: 'storage-management', params: { action: 'new' } })
      },
      showItem(item) {
        localStorage.setItem('storage_editOrSave', JSON.stringify(item.id))
        this.$router.push({ name: 'storage-management', params: { uuid: item.id, action: 'show' } })
      },
      async showVersions(item) {
        this.showVersionsDialog = true
        this.$root.$emit('storage:versions', item)
      },
      close() {
        this.$refs.form.reset()
        if (this.showItemDetailsDialog === true)
          this.showItemDetailsDialog = false
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
      updatePrimary(primary) {
        this.selectedItem.name = primary.name
      },
      saveShow() {
        if (
          this.equalObjectsCompareRule(this.selectedItem, this.editedItem)
        ) {
          this.$toast.info(i18n.t('general.notification.nothingToModify'), {
            icon: 'mdi-information-slab-circle',
            queueable: true
          })
        } else {
          let item = Object.assign({}, this.editedItem)
          let location = null
          if (this.IsJsonString(this.editedItem.location)) {
            location = JSON.parse(this.editedItem.location)
          }
          item.location = JSON.stringify(location)
          item.storageDeviceType = {
            id: item.storageDeviceType.storage_device_type_id,
          }
          item.measurementUnit = this.turfDefaultUnit

          if (this.editedIndex === -1)
            item.company = { id: this.profile.company_id }

          item.longitude = 1
          item.latitude = 1

          const document = {
            documentAuthority: { id: 1 },
            documentType: { id: 1 },
            documentDescription: { id: 1 },
            company: { id: item.company.id },
          }

          this.saveStorage([
            item,
            this.editedIndex,
            document,
            null,
            document,
            null,
            this.$toast,
          ]).then(res => {
            if(res) {
              this.close()
              this.initialize()
            }
          })
        }
      },
      updateLocations(storage) {
        if (storage || storage !== undefined) {
          let data = _.filter(this.locationsList, {
            active: true,
            storage_device_type_id: storage.storage_device_type_id,
          })

          if (data && data.length > 0) {
            this.locationsData = data
          } else {
            this.locationsData = []
          }
          this.editedItem.primaryLocation = null
        } else {
          this.editedItem.primaryLocation = null
          this.locationsData = []
        }
      },
      async loadDependencies() {
        const promises = []
        // add here a list of promises;
        this.profile = JSON.parse(localStorage.getItem('profile'))
        promises.push(
          this.fetchStorageTypesActiveByCompany([
            this.$toast,
          ]),
        )
        promises.push(this.fetchAllUnits(this.$toast))
        await Promise.all(promises)
      },
      updateCountry(country) {
        this.selectedItem.country = country;
        this.editedItem.country = country;
      },
      replace(event) {
        event.currentTarget.value = event.currentTarget.value.toString().replaceAll('"', '')
      },
      replaceSearch(event) {
        this.search = event.currentTarget.value.toString().replaceAll('"', '')
      },
      replaceControlLabel(event) {
        this.editedItem.control_label = event.currentTarget.value.toString().replaceAll('"', '')
      },
      replaceGln(event) {
        this.editedItem.gln = event.currentTarget.value.toString().replaceAll('"', '')
      },
      replaceArea(event) {
        this.editedItem.area = event.currentTarget.value.toString().replaceAll('"', '')
      },
      replaceAddress1(event){
        this.editedItem.address1=  event.currentTarget.value.toString().replaceAll('"','')
      },
      replaceAddress2(event){
        this.editedItem.address2 =  event.currentTarget.value.toString().replaceAll('"','')
      },
      replaceState(event){
        this.editedItem.state =  event.currentTarget.value.toString().replaceAll('"','')
      },
      replaceCity(event){
        this.editedItem.city =  event.currentTarget.value.toString().replaceAll('"','')
      },
      replaceZipCode(event){
        this.editedItem.zipCode =  event.currentTarget.value.toString().replaceAll('"','')
      }
    },

    destroyed() {
      this.$root.$off('setFiltersStorages')
      this.$root.$off('validateItem')

      this.$root.$emit("clear:locations:filters", {
        locations: [],
        storageDevice: []
      })
      this.$store.commit('ui/FILTERS_OBJECTS_ORIGINAL_STATE');
    }
  }
</script>

<style scoped>
  >>> .mapboxgl-map canvas {
    border-radius: 8px !important;
  }
  :deep(.v-chip-group .v-slide-group__content) {
    display: flex !important;
    justify-content: flex-start !important;
  }
  :deep(.v-toolbar__content) {
    padding: 0 !important;
  }
</style>
